<template>
  <v-container fluid style="padding-bottom:80px" class="ml-4 mr-4">
    <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
        </v-col>

        <v-col cols="12">
            <v-card outlined>
                <v-container fluid>
                    <v-row>
                      <v-col class="col-6" sm="6" md="2">
                            <span class="blue-lcd mb-2 font-12">Date </span>
                            <v-autocomplete
                                outlined
                                dense
                                auto-select-first
                                v-model="date_choose"
                                :items="date_chooses"
                                default="" item-value="id" item-text="desc"
                                class="ma-0 pa-0 mt-2 border-12"
                                hide-details=true
                            ></v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <span class="blue-lcd mb-2 font-12">Start Date </span>
                            <v-menu ref="startDateModal" v-model="startDateModal" transition="scale-transition"
                                offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field class="ma-0 mt-2 border-12" v-model="startDate" dense clearable outlined
                                        persistent-hint append-icon="mdi-calendar"
                                        v-bind="attrs" v-on="on" hide-details=true>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="startDate"
                                    no-title @input="startDateModal = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <span class="blue-lcd mb-2 font-12">End Date </span>
                            <v-menu ref="endDateModal"
                            v-model="endDateModal"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    dense
                                    clearable
                                    outlined
                                    v-model="endDate"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 mt-2 border-12"
                                    hide-details=true>
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="endDate"
                                    no-title @input="endDateModal = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <v-btn class="mt-4 border-12" style="padding: 19px;" color="info" elevation="2" small @click="retreiveIndex()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
    </v-row>
    
    <v-row class="mt-2 mr-4">
      <v-col class="col-12">
        <v-card outlined>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersMain"
                  :items="items"
                  :loading="$store.state.overlay"
                  item-class="tr_datatable"
                  fixed-header
                  height="400"
                  items-per-page="9999"
                  hide-default-footer
                  :divider="true"
                  :light="true"
                  :search="searchItem"
                  mobile-breakpoint="0"
                >
                  <template v-slot:top>
                    <v-toolbar flat color="white" class="mt-2 mb-2">
                      <div class="d-flex w-100">
                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Price Comparison </h6>
                        <v-spacer></v-spacer>
                        <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                        <!-- <v-btn
                          small
                          color="#005c37"
                          class="py-5 mr-3 text-white border-12">
                            <v-icon>mdi-file-excel</v-icon>
                            <download-excel
                                class="text"
                                :fetch           = "exportExcel"
                                :data="histori_exports"
                                :before-generate = "startDownload"
                                :before-finish   = "finishDownload">
                                Export Excel
                            </download-excel>
                        </v-btn> -->
                      </div>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small class="mr-2" @click="openDialog(item)" v-on="on">
                              mdi-eye
                          </v-icon>
                        </template>
                        <span>Show</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >

    <v-row>
      <v-container fluid class="ma-4">
      <!-- Header Section -->
      <v-row>
        <v-col cols="12" class="text-center">
          <h3>PT SURYA MAJU SUKSES</h3>
          <h4>COMPARISON PRICE</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-container fluid class="mb-6">
          <v-col cols="6">
            <v-row>
              <v-col cols="2"><strong>Submit Number</strong></v-col>
              <v-col cols="1"><strong>:</strong></v-col>
              <v-col cols="9"> {{valueOpen.trx_id}} </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"><strong>Submit Date</strong></v-col>
              <v-col cols="1"><strong>:</strong></v-col>
              <v-col cols="9">{{valueOpen.dt_trx}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="2"><strong>PRU / PR Number</strong></v-col>
              <v-col cols="1"><strong>:</strong></v-col>
              <v-col cols="9">{{valueOpen.pr_id}}</v-col>
            </v-row>
          </v-col>
        </v-container>
        
      </v-row>

      <!-- Data Table Section -->
      <v-row>
        <v-container fluid class="mb-6">
          <v-data-table
            :headers="headers"
            :items="itemData"
            class="elevation-1 mt-0"
            dense
            hide-default-header
            hide-default-footer
            items-per-page="9999"
            mobile-breakpoint="0"
          >
          <template #header="{ props: { headers } }">
              <thead class="v-data-table-header">
                  <tr>
                      <th
                          rowspan="2"
                          class="text-uppercase text-left"
                      >
                          No
                      </th>
                      <th
                          rowspan="2"
                          class="text-uppercase text-center"
                      >
                          Item Name
                      </th>
                      <th
                          rowspan="2"
                          class="text-uppercase text-center"
                      >
                          OH
                      </th>
                      <th
                          rowspan="2"
                          class="text-uppercase text-center"
                      >
                          Qty
                      </th>
                      <th
                          rowspan="2"
                          class="text-uppercase text-center"
                      >
                          Unit
                      </th>
                      <th
                          colspan="3"
                          class="text-uppercase text-center"
                      >
                          {{ valueOpen.vendor_name1 }}
                      </th>
                      <th
                          colspan="3"
                          class="text-uppercase text-center"
                      >
                        {{ valueOpen.vendor_name2 }}
                      </th>
                      <th
                          colspan="3"
                          class="text-uppercase text-center"
                      >
                        {{ valueOpen.vendor_name3 }}
                      </th>
                      <th
                          colspan="3"
                          class="text-uppercase text-center"
                      >
                        {{ valueOpen.vendor_name4 }}
                      </th>
                      <th
                          colspan="3"
                          class="text-uppercase text-center"
                      >
                        {{ valueOpen.vendor_name5 }}
                      </th>
                  </tr>
                  <tr>
                      <th class="text-center">
                          Choose
                      </th>
                      <th class="text-center">
                          Unit Price
                      </th>
                      <th class="text-center">
                          Total
                      </th>
                      <th class="text-center">
                          Choose
                      </th>
                      <th class="text-center">
                          Unit Price
                      </th>
                      <th class="text-center">
                          Total
                      </th>
                      <th class="text-center">
                          Choose
                      </th>
                      <th class="text-center">
                          Unit Price
                      </th>
                      <th class="text-center">
                          Total
                      </th>
                      <th class="text-center">
                          Choose
                      </th>
                      <th class="text-center">
                          Unit Price
                      </th>
                      <th class="text-center">
                          Total
                      </th>
                      <th class="text-center">
                          Choose
                      </th>
                      <th class="text-center">
                          Unit Price
                      </th>
                      <th class="text-center">
                          Total
                      </th>
                  </tr>
              </thead>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-chip
                  class="mr-2 mt-0 border-start border-secondary"
                  v-on="on"
                  @click="openDialogDetail(item)"
                >
                  {{ item.description }}
                </v-chip>
              </template>
            </v-tooltip>
          </template>
          <!-- <v-dialog v-model="dialogDetail" max-width="400px">
            <v-card>
              <v-card-title class="headline">Item Details</v-card-title>
              <v-card-text>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialogDetail = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog> -->
          <template v-slot:[`item.num`]="{ item }">
            <div class="mt-0">
              {{ item.num }}
            </div>
              
          </template> 
          <template v-slot:[`item.article_id`]="{ item }">
            <div class="mt-0">
              {{ item.article_id }}
            </div>
          </template>        
          <template v-slot:[`item.qty_oh`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.qty_oh) }}
            </div>
          </template>
          <template v-slot:[`item.qty`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.qty) }}
            </div>
          </template>
          <template v-slot:[`item.cek1`]="{ item }">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-checkbox class="mr-2 mt-0 border-start border-secondary" @click="clickOne(item)" v-on="on" v-model="item.cek1"/>
                  </template>
              </v-tooltip>
          </template>
          <template v-slot:[`item.price1`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.price1) }}
            </div>
          </template>
          <template v-slot:[`item.total_price1`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.total_price1) }}
            </div>
          </template>
          <template v-slot:[`item.cek2`]="{ item }">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-checkbox class="mr-2 mt-0 border-start border-secondary" @click="clickTwo(item)" v-on="on" v-model="item.cek2"/>
                  </template>
              </v-tooltip>
          </template>
          <template v-slot:[`item.price2`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.price2) }}
            </div>
          </template>
          <template v-slot:[`item.total_price2`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.total_price2) }}
            </div>
          </template>
          <template v-slot:[`item.cek3`]="{ item }">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-checkbox class="mr-2 mt-0 border-start border-secondary" @click="clickThree(item)" v-on="on" v-model="item.cek3"/>
                  </template>
              </v-tooltip>
          </template>
          <template v-slot:[`item.price3`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.price3) }}
            </div>
          </template>
          <template v-slot:[`item.total_price3`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.total_price3) }}
            </div>
          </template>
          <template v-slot:[`item.cek4`]="{ item }">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-checkbox class="mr-2 mt-0 border-start border-secondary" @click="clickFour(item)" v-on="on" v-model="item.cek4"/>
                  </template>
              </v-tooltip>
          </template>
          <template v-slot:[`item.price4`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.price4) }}
            </div>
          </template>
          <template v-slot:[`item.total_price4`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.total_price4) }}
            </div>
          </template>
          <template v-slot:[`item.cek5`]="{ item }">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-checkbox class="mr-2 mt-0 border-start border-secondary" @click="clickFive(item)" v-on="on" v-model="item.cek5"/>
                  </template>
              </v-tooltip>
          </template>
          <template v-slot:[`item.price5`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.price5) }}
            </div>
          </template>
          <template v-slot:[`item.total_price5`]="{ item }">
            <div class="mt-0">
              {{ $store.getters.convertToCurrencyUs(item.total_price5) }}
            </div>
          </template>
          <!-- subtotal and friends -->
          <template slot="body.append">
            <!-- todo belum diganti -->
            <tr style="border-bottom: 2px solid;">
                <th colspan="4">Chosen Sub Total per Vendor</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(vendor1)}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(vendor2)}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(vendor3)}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(vendor4)}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(vendor5)}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">Sub Total per Quotation</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quo1)}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quo2)}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quo3)}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quo4)}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quo5)}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">Negotiation Result</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quoNego1 - quo1)}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quoNego2 - quo2)}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quoNego3 - quo3)}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quoNego4 - quo4)}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(quoNego5 - quo5)}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">Discount per Quotation</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.amt_dics1)}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.amt_dics2)}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.amt_dics3)}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.amt_dics4)}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.amt_dics5)}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">PPN per Quotation Before Delivery</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.ppn1 / 100 * (quo1 - valueOpen.amt_dics1))}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.ppn2 / 100 * (quo2 - valueOpen.amt_dics2))}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.ppn3 / 100 * (quo3 - valueOpen.amt_dics3))}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.ppn4 / 100 * (quo4 - valueOpen.amt_dics4))}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(valueOpen.ppn5 / 100 * (quo5 - valueOpen.amt_dics5))}}</th> <!-- PO */ -->
            </tr>
            <tr style="border-bottom: 2px solid;">
                <th colspan="4">Total per Quotation</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo1 - valueOpen.amt_dics1) + (valueOpen.ppn1 / 100 * (quo1 - valueOpen.amt_dics1)))}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo2 - valueOpen.amt_dics2) + (valueOpen.ppn2 / 100 * (quo2 - valueOpen.amt_dics2)))}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo3 - valueOpen.amt_dics3) + (valueOpen.ppn3 / 100 * (quo3 - valueOpen.amt_dics3)))}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo4 - valueOpen.amt_dics4) + (valueOpen.ppn4 / 100 * (quo4 - valueOpen.amt_dics4)))}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo5 - valueOpen.amt_dics5) + (valueOpen.ppn5 / 100 * (quo5 - valueOpen.amt_dics5)))}}</th>
            </tr>
            <tr>
                <th colspan="4">Chosen Sub Total</th>
                <th colspan="15" class="text-center">{{$store.getters.convertToCurrencyUs(sumTotalPrice)}}</th>
            </tr>
            <tr>
                <th colspan="4">Chosen PPN</th>
                <th colspan="15" class="text-center">{{$store.getters.convertToCurrencyUs(
                  (valueOpen.ppn1 / 100 * (vendor1)) 
                  + (valueOpen.ppn2 / 100 * (vendor2))
                  + (valueOpen.ppn3 / 100 * (vendor3))
                  + (valueOpen.ppn4 / 100 * (vendor4))
                  + (valueOpen.ppn5 / 100 * (vendor5))
                  )}}</th>
            </tr>
            <tr style="border-bottom: 2px solid;">
                <th colspan="4">Chosen Total</th>
                <th colspan="15" class="text-center">{{$store.getters.convertToCurrencyUs(sumTotalPrice + (
                  (valueOpen.ppn1 / 100 * (vendor1)) 
                  + (valueOpen.ppn2 / 100 * (vendor2))
                  + (valueOpen.ppn3 / 100 * (vendor3))
                  + (valueOpen.ppn4 / 100 * (vendor4))
                  + (valueOpen.ppn5 / 100 * (vendor5))
                ))}}</th>
            </tr>
            <!-- todo belum diitung -->
            <tr style="border-bottom: 2px solid;">
                <th colspan="4">Total Difference</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo1 - valueOpen.amt_dics1) + (valueOpen.ppn1 / 100 * (quo1 - valueOpen.amt_dics1))
                  - (sumTotalPrice + (
                  (valueOpen.ppn1 / 100 * (vendor1)) 
                  + (valueOpen.ppn2 / 100 * (vendor2))
                  + (valueOpen.ppn3 / 100 * (vendor3))
                  + (valueOpen.ppn4 / 100 * (vendor4))
                  + (valueOpen.ppn5 / 100 * (vendor5))
                ))
                )}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo2 - valueOpen.amt_dics2) + (valueOpen.ppn2 / 100 * (quo2 - valueOpen.amt_dics2))
                  - (sumTotalPrice + (
                  (valueOpen.ppn1 / 100 * (vendor1)) 
                  + (valueOpen.ppn2 / 100 * (vendor2))
                  + (valueOpen.ppn3 / 100 * (vendor3))
                  + (valueOpen.ppn4 / 100 * (vendor4))
                  + (valueOpen.ppn5 / 100 * (vendor5))
                ))
                )}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo3 - valueOpen.amt_dics3) + (valueOpen.ppn3 / 100 * (quo3 - valueOpen.amt_dics3))
                  - (sumTotalPrice + (
                  (valueOpen.ppn1 / 100 * (vendor1)) 
                  + (valueOpen.ppn2 / 100 * (vendor2))
                  + (valueOpen.ppn3 / 100 * (vendor3))
                  + (valueOpen.ppn4 / 100 * (vendor4))
                  + (valueOpen.ppn5 / 100 * (vendor5))
                ))
                )}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo4 - valueOpen.amt_dics4) + (valueOpen.ppn4 / 100 * (quo4 - valueOpen.amt_dics4))
                  - (sumTotalPrice + (
                  (valueOpen.ppn1 / 100 * (vendor1)) 
                  + (valueOpen.ppn2 / 100 * (vendor2))
                  + (valueOpen.ppn3 / 100 * (vendor3))
                  + (valueOpen.ppn4 / 100 * (vendor4))
                  + (valueOpen.ppn5 / 100 * (vendor5))
                ))
                )}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs((quo5 - valueOpen.amt_dics5) + (valueOpen.ppn5 / 100 * (quo5 - valueOpen.amt_dics5))
                  - (sumTotalPrice + (
                  (valueOpen.ppn1 / 100 * (vendor1)) 
                  + (valueOpen.ppn2 / 100 * (vendor2))
                  + (valueOpen.ppn3 / 100 * (vendor3))
                  + (valueOpen.ppn4 / 100 * (vendor4))
                  + (valueOpen.ppn5 / 100 * (vendor5))
                ))
                )}}</th>
            </tr>
            <tr>
                <th colspan="4">Shipping Cost With PPN</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs( parseFloat((valueOpen.ppn1 / 100) * valueOpen.deliv_cost1) + parseFloat(valueOpen.deliv_cost1))}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs( parseFloat((valueOpen.ppn2 / 100) * valueOpen.deliv_cost2) + parseFloat(valueOpen.deliv_cost2))}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs( parseFloat((valueOpen.ppn3 / 100) * valueOpen.deliv_cost3) + parseFloat(valueOpen.deliv_cost3))}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs( parseFloat((valueOpen.ppn4 / 100) * valueOpen.deliv_cost4) + parseFloat(valueOpen.deliv_cost4))}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs( parseFloat((valueOpen.ppn5 / 100) * valueOpen.deliv_cost5) + parseFloat(valueOpen.deliv_cost5))}}</th>
            </tr>
            <tr style="border-bottom: 2px solid;">
                <th colspan="4">Grand Total</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(
                  (quo1 - valueOpen.amt_dics1) + (valueOpen.ppn1 / 100 * (quo1 - valueOpen.amt_dics1))
                  + parseFloat((valueOpen.ppn1 / 100) * valueOpen.deliv_cost1) + parseFloat(valueOpen.deliv_cost1)
                  )}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(
                  (quo2 - valueOpen.amt_dics2) + (valueOpen.ppn2 / 100 * (quo2 - valueOpen.amt_dics2))
                  + parseFloat((valueOpen.ppn2 / 100) * valueOpen.deliv_cost2) + parseFloat(valueOpen.deliv_cost2)
                  )}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(
                  (quo3 - valueOpen.amt_dics3) + (valueOpen.ppn3 / 100 * (quo3 - valueOpen.amt_dics3))
                  + parseFloat((valueOpen.ppn3 / 100) * valueOpen.deliv_cost3) + parseFloat(valueOpen.deliv_cost3)
                  )}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(
                  (quo4 - valueOpen.amt_dics4) + (valueOpen.ppn4 / 100 * (quo4 - valueOpen.amt_dics4))
                  + parseFloat((valueOpen.ppn4 / 100) * valueOpen.deliv_cost4) + parseFloat(valueOpen.deliv_cost4)
                  )}}</th>
                <th colspan="3" class="text-right">{{$store.getters.convertToCurrencyUs(
                  (quo5 - valueOpen.amt_dics5) + (valueOpen.ppn5 / 100 * (quo5 - valueOpen.amt_dics5))
                  + parseFloat((valueOpen.ppn5 / 100) * valueOpen.deliv_cost5) + parseFloat(valueOpen.deliv_cost5)
                  )}}</th>
            </tr>
            <tr>
                <th colspan="4">Merk</th>
                <th colspan="3" class="text-right">{{valueOpen.merk1}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{valueOpen.merk2}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.merk3}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{valueOpen.merk4}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.merk5}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">Warranty</th>
                <th colspan="3" class="text-right">{{valueOpen.garansi1}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{valueOpen.garansi2}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.garansi3}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{valueOpen.garansi4}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.garansi5}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">Production Lead Time</th>
                <th colspan="3" class="text-right">{{valueOpen.lead_time1}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{valueOpen.lead_time2}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.lead_time3}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{valueOpen.lead_time4}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.lead_time5}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">Delivery Place</th>
                <th colspan="3" class="text-right">{{valueOpen.deliv_place1}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{valueOpen.deliv_place2}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.deliv_place3}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{valueOpen.deliv_place4}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.deliv_place5}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">Payment Term</th>
                <th colspan="3" class="text-right">{{valueOpen.pay_term1}}</th><!-- WGT OH */ -->
                <th colspan="3" class="text-right">{{valueOpen.pay_term2}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.pay_term3}}</th> <!-- PO */ -->
                <th colspan="3" class="text-right">{{valueOpen.pay_term4}}</th> <!-- Order */ -->
                <th colspan="3" class="text-right">{{valueOpen.pay_term5}}</th> <!-- PO */ -->
            </tr>
            <tr>
                <th colspan="4">Notes</th>
                <th colspan="3" class="text-right"></th><!-- WGT OH */ -->
                <th colspan="3" class="text-right"></th> <!-- Order */ -->
                <th colspan="3" class="text-right"></th> <!-- PO */ -->
                <th colspan="3" class="text-right"></th> <!-- Order */ -->
                <th colspan="3" class="text-right"></th> <!-- PO */ -->
            </tr>
        </template>
          
          </v-data-table>
          <v-dialog v-model="dialogDetail" max-width="800">
            <v-card>
              <v-card-title class="headline">History Price</v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headersDetail"
                  :items="histories"
                  class="elevation-1 mt-0"
                  dense
                  hide-default-footer
                >
                <template v-slot:[`item.unit_price`]="{ item }">
                  {{ item.unit_price | rupiahFormat }}
                </template>
                <template v-slot:[`item.dt_po`]="{ item }">
                  {{ item.dt_po | dateFormat }}
                </template>
                </v-data-table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialogDetail = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
          
      </v-row>
      
      <!-- Footer Section -->
      <v-row>
        <v-container fluid>
          <v-col cols="12">
          <v-row>
            <v-col cols="8">
              <p>DIBUAT OLEH :</p>
              <p>Name: {{ creatorName }}</p>
              <p>Date: {{ valueOpen.dt_trx }}</p>
            </v-col>
            <!-- <v-col cols="4">
              <p>DISETUJUI OLEH :</p>
              <p>Name: Soebratra</p>
              <p>Date: ...</p>
            </v-col> -->
            <v-col cols="4">
              <p>DISETUJUI OLEH :</p>
              <p>Name: {{ finalizedName }}</p>
              <p>Date: {{ valueOpen.finalize_date }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="col-12" sm="col-12" lg="col-1" class="red--text">
              <strong>KETERANGAN:</strong>
            </v-col>
            <v-col xs="col-12" sm="col-12" lg="col-1">
              {{ valueOpen.note }}
            </v-col>
          </v-row>
        </v-col>
        </v-container>
        
      </v-row>
    </v-container>
    </v-row>
    

      <v-row>
        <v-col class="col-12 text-center">
          <v-btn
            class="ma-2 border-12 white-text" style="padding: 19px;" elevation="2" small color="primary"
            @click="finalize()" :disabled=approvedDisabled
          >
            {{ finalButtonName }}
          </v-btn>

          <v-btn
            class="ma-2 border-12 white-text" style="padding: 19px;" elevation="2" small color="success"
            @click="save()" :disabled=approvedDisabled
          >
            Save
          </v-btn>

          <v-btn
            class="ma-2 border-12 white-text" style="padding: 19px;" elevation="2" small color="grey"
            @click="closeDialog()"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-dialog>
  </v-container>
  
  
</template>

<script>
import {backendApi} from "@/backend-api/backend-api-sr"
import {HphApproval} from "../../../backend-api/sms/approval/HphApproval"

export default {
  data() {
    return {
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        title: null,
        visible: false
      },
      breadcumbs: [
          {
          text: 'Surya Maju Sukses',
          disabled: false,
          href: '/admin/sms',
          },
          {
          text: 'Approval',
          disabled: true,
          href: 'breadcrumbs_link_2',
          },
          {
          text: 'Comparison Price',
          disabled: true,
          href: 'breadcrumbs_link_2',
          },
      ],
      dialog:false,
      dialogDetail: false,
      searchItem:'',
      startDateModal:false,
      startDate:'',
      endDateModal:false,
      endDate:'',
      date_choose: 'S',
      date_chooses: [
        {id: "S", desc:'Submit'},
        {id: "F", desc:'Finalize'}
      ],
      headersMain:[
        {text: 'Action', align: 'center', value: 'action',},
        {text: 'Office', align: 'start', value: 'office'},
        {text: 'Submit Num', value: 'trx_id' , align:'center'},
        {text: 'Submit Date', value: 'dt_trx' , align:'right'},
        {text: 'Finalize Date', value: 'finalize_date' , align:'right'},
        {text: 'Pr List', value: 'pr_id' , align:'right'},
        {text: 'Note', value: 'note' , align:'right'},
      ],
      headers: [
        // { text: 'No', value: 'no' },
        // { text: 'Item Name', value: 'item_name' },
        // { text: 'Qty', value: 'qty' },
        // { text: 'Unit', value: 'unit' },
        // { text: 'Comparison', value: 'comparison' },
          {
              text: 'Num',
              align: 'start',
              value: 'num', 
              align:'center'},
          { text: 'Article', value: 'description' , align:'left'},
          // {text: 'Action', align: 'center', value: 'action',},
          { text: 'OH', value: 'qty_oh' , align:'right'},
          { text: 'Qty', value: 'qty' , align:'right'},
          { text: 'UOM', value: 'unit_meas' , align:'right'},
          { text: 'cek1', value: 'cek1' , align:'center'},
          { text: 'price1', value: 'price1' , align:'right'},
          { text: 'total_price1', value: 'total_price1' , align:'right'},
          { text: 'cek2', value: 'cek2' , align:'center'},
          { text: 'price2', value: 'price2' , align:'right'},
          { text: 'total_price2', value: 'total_price2' , align:'right'},
          { text: 'cek3', value: 'cek3' , align:'center'},
          { text: 'price3', value: 'price3' , align:'right'},
          { text: 'total_price3', value: 'total_price3' , align:'right'},
          { text: 'cek4', value: 'cek4' , align:'center'},
          { text: 'price4', value: 'price4' , align:'right'},
          { text: 'total_price4', value: 'total_price4' , align:'right'},
          { text: 'cek5', value: 'cek5' , align:'center'},
          { text: 'price5', value: 'price5' , align:'right'},
          { text: 'total_price5', value: 'total_price5' , align:'right'},
      ],
      headersDetail: [
          { text: 'Vendor', align: 'start', value: 'vendor_name', align:'left'},
          { text: 'Date PO', value: 'dt_po' , align:'left'},
          { text: 'Unit Price', value: 'unit_price' , align:'left'},
      ],
      nestedHeaders: [
        { text: 'Vendor', value: 'vendor' },
        { text: 'Unit Price', value: 'unit_price' },
        { text: 'Total', value: 'total' },
      ],
      prices:[],
      items:[],
      itemData: [],
      itemChoosen:[],
      histories: [],
      totalChoosen:0,
      qtyChoosen:0,
      sumTotalPrice:0,
      avgPrice:0,
      vendor1: 0,
      vendor2: 0,
      vendor3: 0,
      vendor4: 0,
      vendor5: 0,
      quo1: 0,
      quo2: 0,
      quo3: 0,
      quo4: 0,
      quo5: 0,
      quoNego1: 0,
      quoNego2: 0,
      quoNego3: 0,
      quoNego4: 0,
      quoNego5: 0,
      valueOpen:{},
      dialogOfficeId:'',
      dialogTrxId:'',
      approvedDisabled:true,
      creatorName:'',
      finalizedName:'',
      finalButtonName: '',
      }
    },
    computed: {
      transformedData() {
        return this.itemData.map(item => ({
          ...item,
          cek1: item.cek1 === "Y",
          cek2: item.cek2 === "Y",
          cek3: item.cek3 === "Y",
          cek4: item.cek4 === "Y",
          cek5: item.cek5 === "Y",
        }));
      }
    },
    filters: {
        twoDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(2);
        },
        oneDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(1);
        },
        noDecimals(value) {
            if (!value) return '';
            return parseFloat(value).toFixed(0);
        },
        rupiahFormat(value) {
            if (!value) return '';
            let formatter = new Intl.NumberFormat('id-ID', {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            });
            return formatter.format(value);
        },
        dateFormat(value) {
            if (!value) return '';
            let date = new Date(value);
            return date.toISOString().substr(0, 10);
        }
    },
    async mounted(){
      await this.getEnvConf()
    },
    methods:{
      async openDialog(value){
        await this.retreiveItem(value)
        this.dialog = true
      },
      // async openDialogDetail(item) {
      //   // this.selectedItem = item;
      //   console.log(item.article_id)
      //   await this.getDetail(item.article_id)
      //   this.dialogDetail = true;
      // },
      async openDialogDetail(item) {
        if (!item || !item.article_id) {
          console.error("Invalid item or missing article_id:", item);
          return;
        }
        console.log(item.article_id);
        await this.getDetail(item.article_id);
        this.dialogDetail = true;
      },
      closeDialog(){
        this.clearDetail()
        this.dialog = false
      },
      clearDetail(){
        this.itemData=[]
        this.itemChoosen=[]
        this.prices=[]
        this.totalChoosen=0
        this.qtyChoosen=0
        this.sumTotalPrice=0
        this.avgPrice=0
        this.vendor1= 0
        this.vendor2= 0
        this.vendor3= 0
        this.vendor4= 0
        this.vendor5= 0
        this.quo1= 0
        this.quo2= 0
        this.quo3= 0
        this.quo4= 0
        this.quo5= 0
        this.quoNego1= 0
        this.quoNego2= 0
        this.quoNego3= 0
        this.quoNego4= 0
        this.quoNego5= 0
        this.dialogOfficeId = ''
        this.dialogTrxId = ''
        this.creatorName = ''
        this.finalizedName = ''
        this.finalButtonName = ''
      },
      async getEnvConf(){
        this.$store.dispatch('setOverlay', true)
        const respDataSales = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=STARCON&appl_id=WEBSTARCON&var_id=APRV_COMP_PRICE`, null, false, false, false)  
        console.log(respDataSales)
        if (respDataSales.status == 200) {
            if (respDataSales.data.data == "Y"){
              this.approvedDisabled = false
            } else {
              this.approvedDisabled = true
            }
            // await this.retreiveIndex()
            this.$store.dispatch('setOverlay', false)
        } else {
          this.$store.dispatch('setOverlay', false)
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: "Failed to get env conf.",
            visible: true
          };
        }
      },
      async getCreator(value){
        let reqBody = {
          "user_id3" : value,
        }
        const rData = await this.getEmployee(reqBody)
        if (rData.status === 200) {
          console.log("employee", rData)
          this.creatorName = (rData.data.data)?rData.data.data.name : value
            // this.items = rData.data.data
            // console.log('index')
            // console.log(this.items)
        } else {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: "Failed to get employee name.",
            visible: true
          };
        }
      },
      async getApprovedBy(value){
        // console.log('getApprovedBy', value)
      },
      async getFinalized(value){
        // console.log('get finalized', value)
        let reqBody = {
          "user_id2" : value,
        }
        const rData = await this.getEmployee(reqBody)
        if (rData.status === 200) {
          // console.log("employee", rData)
          this.finalizedName = (rData.data.data)?rData.data.data.name : value
            // this.items = rData.data.data
            // console.log('index')
            // console.log(this.items)
        } else {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: "Failed to get employee name.",
            visible: true
          };
        }
      },
      async getEmployee(value){
        // console.log('get employee', value)
        // console.log('value store ', this.$store.getters.UsernameUser)
        const rData = await HphApproval.fetchEmployee('', value)
        return rData
      },
      async retreiveIndex(value){
        if (value != 1){
          this.clearDetail()
        }
        // const url = `?office_id=1`
        var startDate = this.startDate ? new Date(this.startDate).toISOString().slice(0, 10) : ''
        var endDate = this.endDate ? new Date(this.endDate).toISOString().slice(0, 10) : ''
        let reqBody = {
          "office_id" : "1",
          "id": this.date_choose,
          "dt_start": startDate,
          "dt_end": endDate,
        }
        this.$store.dispatch('setOverlay', true)
        const rData = await HphApproval.fetchIndex('', reqBody)
        if (rData.status === 200) {
            this.items = rData.data.data
            // console.log('index')
            // console.log(this.items)
        } else {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: "Failed to get header data.",
            visible: true
          };
        }
        this.$store.dispatch('setOverlay', false)
      },
      async retreiveItem(value){
        this.clearDetail();
        // console.log("value", value)
        this.valueOpen = value;
        this.dialogOfficeId = value.office_id
        this.dialogTrxId = value.trx_id
        // const url = `?office_id=${value.office_id}&trx_id=${value.trx_id}`
        let reqBody = {
          "office_id" : value.office_id,
          "trx_id" : value.trx_id,
        }
        this.$store.dispatch('setOverlay', true)
        const rData = await HphApproval.fetchItem('', reqBody)
        if (rData.status === 200) {
            this.itemData = rData.data.data
            this.itemData = this.transformedData
            console.log('ret item')
            console.log(this.itemData)
            this.calculateQuoOnlyOnce()
            this.calculatePrice()
            await this.getCreator(value.user_id)
            await this.getFinalized(value.finalized_by)
            console.log("Final Name = ", this.finalizedName)
            this.finalButtonName = (this.finalizedName.trim() != '') ? "Unfinalized" : "Finalized"
        }
        this.$store.dispatch('setOverlay', false)
      },
      async save(){
        // console.log(this.itemData)
        this.$store.dispatch('setOverlay', true)
        let reqBody = {
          "finalize" : "N",
          "dataSend" : this.itemData,
          "office_id" : this.dialogOfficeId,
          "trx_id" : this.dialogTrxId,
        }
        const respData = await HphApproval.save(``, reqBody, false, false, false)
        if (respData.status === 200) {
          this.snackbar = {
            color: "success",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Info",
            text: respData.data.data,
            visible: true
          };
          this.$store.dispatch('setOverlay', false)
        } else {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: respData.data.data,
            visible: true
          };
          this.$store.dispatch('setOverlay', false)
        }
      },
      async finalize(){
        (this.finalButtonName == "Finalized") ? this.final : this.unfinalized;
        
        this.$store.dispatch('setOverlay', true)
        let reqBody = {
          "finalize" : "U",
          "dataSend" : this.itemData,
          "office_id" : this.dialogOfficeId,
          "trx_id" : this.dialogTrxId,
        }
        if (this.finalButtonName == "Finalized"){
          reqBody = {
            "finalize" : "Y",
            "dataSend" : this.itemData,
            "office_id" : this.dialogOfficeId,
            "trx_id" : this.dialogTrxId,
          }
        }
        // console.log('value open ', this.valueOpen)
        const respData = await HphApproval.save(``, reqBody, false, false, false)
        if (respData.status === 200) {
          let now = new Date();
          if (this.finalButtonName == "Finalized"){
            this.finalButtonName = "Unfinalized"
            await this.getFinalized(this.$store.getters.UsernameUser)
            now = new Date()
          } else {
            this.finalButtonName = "Finalized"
            this.finalizedName = ""
            now = new Date('1900-01-01T00:00:00.000Z')
          }
          this.valueOpen.finalize_date = now.toISOString().split('T')[0]
          await this.retreiveIndex('1')
          this.snackbar = {
            color: "success",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Info",
            text: respData.data.data,
            visible: true
          };
          this.$store.dispatch('setOverlay', false)
        } else {
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: respData.data.data,
            visible: true
          };
          this.$store.dispatch('setOverlay', false)
        }
      },
      cekUnique(array, obj){
        // console.log('cek unique')
        return array.some(item => item.trx_id === obj.trx_id && 
            item.num === obj.num &&
            item.office_id === obj.office_id &&
            item.pr_id === obj.pr_id &&
            item.pr_item === obj.pr_item &&
            item.article_id === obj.article_id);
      },
      setCekBoxValue(array, obj, cek){
        let val = array.findIndex(item => item.trx_id === obj.trx_id && 
            item.num === obj.num &&
            item.office_id === obj.office_id &&
            item.pr_id === obj.pr_id &&
            item.pr_item === obj.pr_item &&
            item.article_id === obj.article_id);
        // console.log(val)
        // console.log("setCekBoxValue")
        switch (cek) {
          case 1:
            // console.log(this.itemData[val])
            this.itemData[val].cek2 = false
            this.itemData[val].cek3 = false
            this.itemData[val].cek4 = false
            this.itemData[val].cek5 = false
            // this.itemData[val].cek1 = (this.itemData[val].cek1==='Y' || this.itemData[val].cek1===true) ? false : true
            if (this.itemData[val].cek1){
              this.itemData[val].aprroved_vendor = this.valueOpen.vendor1
              this.itemData[val].approved_price = this.itemData[val].price1
            }
            console.log(this.itemData)
            break;
          case 2:
            this.itemData[val].cek1 = false
            this.itemData[val].cek3 = false
            this.itemData[val].cek4 = false
            this.itemData[val].cek5 = false
            if (this.itemData[val].cek2){
              this.itemData[val].aprroved_vendor = this.valueOpen.vendor2
              this.itemData[val].approved_price = this.itemData[val].price2
            }
            console.log(this.itemData)
            break;
          case 3:
            this.itemData[val].cek1 = false
            this.itemData[val].cek2 = false
            this.itemData[val].cek4 = false
            this.itemData[val].cek5 = false
            if (this.itemData[val].cek3){
              this.itemData[val].aprroved_vendor = this.valueOpen.vendor3
              this.itemData[val].approved_price = this.itemData[val].price3
            }
            console.log(this.itemData)
            break;
          case 4:
            this.itemData[val].cek1 = false
            this.itemData[val].cek2 = false
            this.itemData[val].cek3 = false
            this.itemData[val].cek5 = false
            if (this.itemData[val].cek4){
              this.itemData[val].aprroved_vendor = this.valueOpen.vendor4
              this.itemData[val].approved_price = this.itemData[val].price4
            }
            console.log(this.itemData)
            break;
          case 5:
            this.itemData[val].cek1 = false
            this.itemData[val].cek2 = false
            this.itemData[val].cek3 = false
            this.itemData[val].cek4 = false
            if (this.itemData[val].cek5){
              this.itemData[val].aprroved_vendor = this.valueOpen.vendor5
              this.itemData[val].approved_price = this.itemData[val].price5
            }
            console.log(this.itemData)
            break;
          default:
            break;
        }
        this.calculatePrice()
        
      },
      remove(obj){
        // console.log('remove')
        this.itemChoosen = this.itemChoosen.filter(item => !(item.trx_id === obj.trx_id && 
            item.num === obj.num &&
            item.office_id === obj.office_id &&
            item.pr_id === obj.pr_id &&
            item.pr_item === obj.pr_item &&
            item.article_id === obj.article_id));
      },
      calculateQuoOnlyOnce(which){
        let countPrices = 0;
        this.itemData.forEach(item => {
          // Check each cek property (cek1 to cek5)
          // console.log('calculate item')
          // console.log(item)
          this.quo1 += parseFloat(item.total_price1);
          this.quo2 += parseFloat(item.total_price2);
          // console.log("quotation 2 kehitung berapa kali", this.quo2)
          this.quo3 += parseFloat(item.total_price3);
          this.quo4 += parseFloat(item.total_price4);
          this.quo5 += parseFloat(item.total_price5);
          this.quoNego1 += parseFloat(item.total_nego1);
          this.quoNego2 += parseFloat(item.total_nego2);
          this.quoNego3 += parseFloat(item.total_nego3);
          this.quoNego4 += parseFloat(item.total_nego4);
          this.quoNego5 += parseFloat(item.total_nego5);
          for (let i = 1; i <= 5; i++) {
            let cekKey = `cek${i}`;
            let priceKey = `price${i}`;
            let totalPriceKey = `total_price${i}`;

            // If cekX is true, add total_priceX to sum and priceX to prices array
            if (item[cekKey]) {
              this.sumTotalPrice += parseFloat(item[totalPriceKey]);
              this.prices.push(parseFloat(item[priceKey]));
              countPrices++;
            }
          }
        });
      },
      calculatePrice(which){
        this.sumTotalPrice = 0;
        this.vendor1= 0
        this.vendor2= 0
        this.vendor3= 0
        this.vendor4= 0
        this.vendor5= 0
        // let prices = [];
        // let countPrices = 0;

        // Iterate over each item in the data
        this.itemData.forEach(item => {
          // Check each cek property (cek1 to cek5)
          // console.log('calculate item')
          // console.log(item)
          // this.quo1 += parseFloat(item.total_price1);
          // this.quo2 += parseFloat(item.total_price2);
          // console.log("quotation 2 kehitung berapa kali", this.quo2)
          // this.quo3 += parseFloat(item.total_price3);
          // this.quo4 += parseFloat(item.total_price4);
          // this.quo5 += parseFloat(item.total_price5);
          for (let i = 1; i <= 5; i++) {
            let cekKey = `cek${i}`;
            let priceKey = `price${i}`;
            let totalPriceKey = `total_price${i}`;

            // If cekX is true, add total_priceX to sum and priceX to prices array
            if (item[cekKey]) {
              this.sumTotalPrice += parseFloat(item[totalPriceKey]);
              // prices.push(parseFloat(item[priceKey]));
              // countPrices++;
            }
          }
        });

        // Calculate average price
        this.avgPrice = this.prices.length > 0 ? this.prices.reduce((acc, val) => acc + val, 0) / this.prices.length : 0;

        this.itemData.forEach(item => {
          if (item.cek1) {
            this.vendor1 += parseFloat(item.total_price1);
          }
          if (item.cek2) {
            this.vendor2 += parseFloat(item.total_price2);
          }
          if (item.cek3) {
            this.vendor3 += parseFloat(item.total_price3);
          }
          if (item.cek4) {
            this.vendor4 += parseFloat(item.total_price4);
          }
          if (item.cek5) {
            this.vendor5 += parseFloat(item.total_price5);
          }
        });


        // Output results
        // console.log(this.totalPrices)
        // console.log('Sum of total prices:', this.sumTotalPrice);
        // console.log('Average of prices:', this.avgPrice);
      },
      clickOne(value){
        // console.log(this.$store.getters.UsernameUser)
        // console.log(value)
        // console.log('awal')
        // console.log(this.itemChoosen)
        // kalau dapet langsung true
        let val = this.cekUnique(this.itemChoosen, value);
        // kalau uncheck langsung remove dari item choosen
        // and reset value di items
        // console.log(val)
        (!val) ? this.itemChoosen.push(value) : this.remove(value)
        // disini lakukan ganti value thd cekbox, sehingga hanya 1 cek box yang isa dipilih
        this.setCekBoxValue(this.itemData, value, 1)
        // console.log('final')
        // console.log(this.itemChoosen)
      },
      clickTwo(value){
        // console.log(value)
        let val = this.cekUnique(this.itemChoosen, value);
        (!val) ? this.itemChoosen.push(value) : this.remove(value)
        this.setCekBoxValue(this.itemData, value, 2)
      },
      clickThree(value){
        // console.log(value)
        let val = this.cekUnique(this.itemChoosen, value);
        (!val) ? this.itemChoosen.push(value) : this.remove(value)
        this.setCekBoxValue(this.itemData, value, 3)
      },
      clickFour(value){
        // console.log(value)
        let val = this.cekUnique(this.itemChoosen, value);
        (!val) ? this.itemChoosen.push(value) : this.remove(value)
        this.setCekBoxValue(this.itemData, value, 4)
      },
      clickFive(value){
        // console.log(value)
        let val = this.cekUnique(this.itemChoosen, value);
        (!val) ? this.itemChoosen.push(value) : this.remove(value)
        this.setCekBoxValue(this.itemData, value, 5)
      },
      // async getDetail(){
      //   this.$store.dispatch('setOverlay', true)  

      //   var reqBody = {
      //     'article_id': this.item.article_id ? this.item.article_id : '',
      //   }

      //   const respData = await backendApi.fetchCore('/api/v3/sms/approval/comparison-price/history-price', reqBody, false, false, false);
      //       if (respData.status === 200) {
      //           console.log("API Response Data:", respData.data); 
      //           if (Array.isArray(respData.data)) {
      //               this.histories = respData.data; 
      //           } else {
      //               console.error("Expected an array but got:", typeof respData.data);
      //               this.histories = []; 
      //           }
      //           // this.$store.dispatch('setOverlay', false);
      //           // this.summary();
      //           console.log("Histories:", this.histories); 
      //       }
        
      //   this.$store.dispatch('setOverlay', false)  
      // },
      async getDetail(article_id) {
        this.$store.dispatch('setOverlay', true);

        var reqBody = {
          'article_id': article_id || '',
        };

        try {
          const respData = await backendApi.fetchCore('/api/v3/sms/approval/comparison-price/history-price', reqBody, false, false, false);
          if (respData.status === 200) {
            console.log("API Response Data:", respData.data.data); 
            if (Array.isArray(respData.data.data)) {
              this.histories = respData.data.data; 
            } else if (typeof respData.data.data === 'object' && respData.data.data !== null) {
              console.warn("Expected an array but got an object, converting to array.");
              this.histories = [respData.data.data];
            } else {
              console.error("Unexpected response type:", typeof respData.data.data);
              this.histories = []; 
            }
            console.log("Histories:", this.histories); 
          }
        } catch (error) {
          console.error("API call failed:", error);
        } finally {
          this.$store.dispatch('setOverlay', false);
        }
      },
    }
}
</script>

<style scoped>
.elevation-1 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.style-1 td {
  height: 50px !important;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.yellow--text {
  color: #ffeb3b;
}
.wrap-table {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
